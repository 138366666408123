import { ToastProgrammatic as Toast } from 'buefy';

const notEmpty = (fieldValue, isRequired) => {
  if (!isRequired)
    return {
      value: true,
      message: '',
    };
  const isValid = !!fieldValue;
  return {
    value: isValid,
    message: isValid ? '' : `Please enter value for required field`,
  };
};

const hasMinLength = (fieldValue, isRequired, minValue) => {
  if (!isRequired && !fieldValue)
    return {
      value: true,
      message: '',
    };
  const isValid = fieldValue?.length >= minValue;
  return {
    value: isValid,
    message: isValid ? '' : `Please enter a valid value (equal or more than ${minValue} symbols)`,
  };
};

const isName = (fieldValue, isRequired) => {
  if (!fieldValue && !isRequired)
    return {
      value: true,
      message: '',
    };

  const regExp = /^[a-zA-Z'\s]*$/;
  const isValid = regExp.test(fieldValue);
  return {
    value: isValid,
    message: isValid ? '' : `Please enter a valid value (only letters)`,
  };
};

const isZipCodeValid = (fieldValue, isRequired, countryValue) => {
  let isValid;
  if (!fieldValue && !isRequired)
    return {
      value: true,
      message: '',
    };

  const numericOnly = new RegExp(/^[0-9]*$/);
  const alphaNum = new RegExp(/^[0-9a-zA-Z\s]*$/);
  const canadaRegExp = new RegExp(
    /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  );

  if (!fieldValue) {
    return {
      value: false,
      message: 'Zipcode is required'
    }
  }

  if (countryValue === 'US' || countryValue === 'USA' || countryValue === 'United States') {
    isValid = numericOnly.test(fieldValue) && fieldValue.length === 5;
  } else if (countryValue === 'CA' || countryValue === 'Canada') {
    isValid = canadaRegExp.test(fieldValue);
  } else {
    isValid = alphaNum.test(fieldValue) && fieldValue.length >= 2;
  }

  return {
    value: isValid,
    message: isValid ? '' : `Please enter a valid zipcode`,
  };
};

const getAge = (born, now) => {
  const birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
  if (now >= birthday) return now.getFullYear() - born.getFullYear();
  else return now.getFullYear() - born.getFullYear() - 1;
};

const isAgeValid = (fieldValue, isRequired, minValue) => {
  const date = new Date(fieldValue);
  const timezoneOffset = date.getTimezoneOffset();
  const offsetDate = new Date(date.getTime() + timezoneOffset * 60 * 1000);

  const age = getAge(offsetDate, new Date());
  const isValid = age >= minValue;

  return {
    value: isValid,
    message: isValid ? '' : `Required age is ${minValue} or more`,
  };
};

const sanitizeSSNInput = (value) => {
  if (!value) {
    return '';
  }
  // allow only dashes and numbers and no spaces
  return value
    .split('')
    .filter((c) => c === '-' || !isNaN(c))
    .join('')
    .replace(/\s/g, '');
};

const ssnInputContainsUniqueDigits = (value) => {
  if (!value) {
    return false;
  }
  const uniqueDigits = [...new Set(sanitizeSSNInput(value).split(''))];
  return uniqueDigits.length > 1 && value.toString() !== '123456789';
};

const isValidSsn = (fieldValue, isRequired) => {
  let isValid = true;
  if (!fieldValue && !isRequired)
    return {
      value: true,
      message: '',
    };

  // remove dashes and spaces to test for numeric characters only and proper length
  const sanitizedValue = fieldValue ? fieldValue.replace(/-/g, '').replace(/\s/g, '') : null;
  const sanitizedSSN = sanitizeSSNInput(sanitizedValue);

  // any 'true' fail condition will cause validation to fail
  const failConditions = [
    !sanitizedValue,
    sanitizedValue?.length !== 9,
    !ssnInputMatchesRegexp(fieldValue),
    !ssnInputContainsUniqueDigits(sanitizedValue),
    sanitizedValue !== sanitizedSSN,
  ];

  for (const condition of failConditions) {
    if (condition) {
      isValid = false;
    }
  }

  return {
    value: isValid,
    message: isValid ? '' : `Please enter a valid SSN`,
  };
};

const isPhoneValid = (fieldValue, isRequired, maskValue) => {
  let isValid = true;
  if ((!fieldValue && !isRequired) || (fieldValue?.length && !maskValue))
    return {
      value: true,
      message: '',
    };

  const digitsFromVal = fieldValue?.replace(/[\s-\(\).]/g, '');
  const digitsFromMask = maskValue.match(/#/g).join('');

  isValid = digitsFromVal?.length === digitsFromMask.length;

  return {
    value: isValid,
    message: isValid ? '' : `Please enter a phone number that match a mask`,
  };
};

const isOneOfList = (fieldValue, isRequired, list) => {
  if ((!fieldValue && !isRequired) || !list.length)
    return {
      value: true,
      message: '',
    };

  const isValid = list.findIndex((item) => item.toLowerCase() === fieldValue.toLowerCase()) > -1;
  return {
    value: isValid,
    message: isValid ? '' : `Please select an option from suggestions list`,
  };
};

export const validationsMap = new Map([
  ['REQUIREDINPUT', notEmpty],
  ['NAMEINPUT', isName],
  ['MINLENGTH', hasMinLength],
  ['ZIPCODE', isZipCodeValid],
  ['AGEVALIDATION', isAgeValid],
  ['VALIDSSN', isValidSsn],
  ['PHONEMASKVALIDATION', isPhoneValid],
  ['COUNTRYINPUT', isOneOfList],
  ['STATEINPUT', isOneOfList],
]);

export const ssnInputMatchesRegexp = (value) => {
  if (!value) {
    return false;
  }
  // allowing specific Turn test cases to pass
  // ref: https://apidoc.turn.ai/docs/public-api/unvianbo9i1wk-turn-api#span-stylecolor-5169f6-test-identity-data-span
  // 1. 986-51-3295
  // 2. 986-34-5090
  if (value === '986-51-3295' || value === '986-34-5090') {
    return true;
  }
  let regex = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
  return regex.test(value);
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isCustomEmailValid = (emailData) => {
  const { toEmails, ccEmails, bccEmails, subject, message } = emailData;

  const callToast = (message) => {
    Toast.open({
      message,
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  };

  //Validate: at least one TO email exists
  if (!toEmails.length) {
    callToast('A TO email is needed to proceed -- please update and try again to proceed');
    return false;
  }

  //Validate: valid TO emails
  toEmails.forEach((email) => {
    if (!isValidEmail(email)) {
      callToast(`Invalid TO email found(${email}). Please update and try again to proceed.`);
      return false;
    }
  });

  ccEmails.forEach((email) => {
    if (!isValidEmail(email)) {
      callToast(`Invalid CC email found(${email}). Please update and try again to proceed.`);
      return false;
    }
  });

  //Validate: valid BCC emails
  bccEmails.forEach((email) => {
    if (!isValidEmail(email)) {
      callToast(`Invalid BCC email found(${email}). Please update and try again to proceed.`);
      return false;
    }
  });

  //Validate subject
  if (!subject || !subject.trim()) {
    callToast('A subject is needed to proceed -- please update and try again to proceed');
    return false;
  }

  //Validate body
  if (!message || !message.trim()) {
    callToast(
      'A message (i.e., containing the URL being sent) is needed to proceed -- please update and try again to proceed',
    );
    return false;
  }

  return true;
};
