import { useBillingStore } from '@/pages/Settings/stores/billing-store.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import generalApiService from '@/services/general-api-service.js';
import organizationApiService from '@/services/organization-api-service.js';
import userGroupsApiService from '@/services/user-groups-api-service.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { createThumbnailFromFile, isUploadedFileSizeValid } from '@/utils/helpers/functions.js';
import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy';
import { defineStore } from 'pinia';
import Vue, { nextTick } from 'vue';


export const useSettingsPageStore = defineStore('settings', {
  state: () => ({
    harbourStore: useHarbourStore(),
    billingStore: useBillingStore(),
    templatesStore: useTemplatesStore(),
    title: 'Contact info',
    subtitle: 'Update your account info',
    userFullName: '',
    userNickName: '',
    phoneNumber: '',
    jobTitle: '',
    orgData: {},
    initialPersonalData: {},
    initialOrgData: {},
    orgName: '',
    orgWebSite: '',
    orgDescription: '',
    orgAdmins: null,
    selectedColorTheme: null,
    orgUpdateDateTime: Date.now(),
    orgCmsProperties: null,
    billingEmail: '',
    storedSignatures: [],
    storedInitials: [],
    organizationAdmins: [],
    orgDefaultGroupUsers: null,
    listUserGroups: [],
    listUserGroupsFiltered: [],
    userGroupsLoaded: false,
    //TODO this data structure is a copy of harbourStore.profilePictures
    usersData: {},
    agGridApi: null,
    agGridColumnApi: null,
    detailsGridApi: null,
    detailsGridColumnApi: null,
    editableGroup: {},
    isEditGroupModalOpened: false,
    isAddGroupModalOpened: false,
    isAddUsersModalOpened: false,
    isLoading: false,
    isTableLoading: false,
    isBillingDisabled: true,
    showBillingWarning: false,
    dataLoaded: false,
    orgDataLoaded: false,
    quickFilterTimeout: null,
    textFilterValue: null,
  }),

  getters: {
    getUserEmail: (state) => {
      return (
        state.harbourStore.contextDict?.systememail || state.harbourStore.contextDict?.useremail
      );
    },
    isOrgAdmin: (state) => {
      return (state.harbourStore.contextDict?.auth_roles || []).includes('orgAdmin');
    },
    isGroupAdmin: (state) => {
      return (state.harbourStore.contextDict?.auth_roles || []).includes('orgGroupAdmin');
    },
    currentOrgId: (state) => {
      return state.harbourStore.contextDict?.organizationid || '';
    },
    currentTitle: (state) => {
      return state.title;
    },
    currentSubtitle: (state) => {
      return state.subtitle;
    },
  },

  actions: {
    async init() {
      if (this.dataLoaded) return;
      await this.getStoredValues();
      await this.getUserGroups();
      await this.loadAllUsersData();
    },

    updateTextFilters(textFilter) {
      // collapse all groups (reset)
      if (this.agGridApi) {
        this.agGridApi.forEachNode(node => {node.expanded = false;});
        this.agGridApi.onGroupExpandedOrCollapsed();
      }

      // reset highlight
      Object.keys(this.usersData).forEach((email) => {
        if (this.usersData[email]) this.usersData[email].highlight = false;
      });

      this.textFilterValue = textFilter;
      const textFilterLower = textFilter?.toLowerCase() || '';
      
      // for finding members by email or name
      const memberPredicate = (member) => {
        const emailMatch = member.email.toLowerCase().includes(textFilterLower);
        const nameMatch = this.usersData[member.email]?.name?.toLowerCase().includes(textFilterLower);
        return Boolean(emailMatch || nameMatch);
      };
      
      // timeout for debounce
      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        // reset filtered list
        this.listUserGroupsFiltered = JSON.parse(JSON.stringify(this.listUserGroups));

        if (!textFilter) {
          return;
        }

        this.listUserGroupsFiltered = this.listUserGroupsFiltered.filter((group) => {
          const { name, members } = group;
          const hasFoundMembers = members.some(memberPredicate);
          const hasFoundGroups = name.toLowerCase().includes(textFilterLower);

          return Boolean(hasFoundGroups || hasFoundMembers);
        });

        // bump found members to top
        this.listUserGroupsFiltered.forEach((group) => {
          group.members.forEach((member) => {
            if (!memberPredicate(member)) return;
            const memberIndex = group.members.findIndex((m) => memberPredicate(m));
            // move found member to the top
            group.members.splice(memberIndex, 1);
            if (this.usersData[member.email] ) this.usersData[member.email].highlight = true;
            group.members.unshift(member);
          });
        });

        // expand groups
        nextTick(() => {
          if (this.agGridApi) {
            this.agGridApi.forEachNode(node => {node.expanded = true});
            this.agGridApi.onGroupExpandedOrCollapsed();
          }
        });
      }, 800);
    },

    setTitle(title) {
      this.title = title || '';
    },

    setSubtitle(text) {
      this.subtitle = text || '';
    },

    setEditableGroup(group) {
      this.editableGroup = group;
    },

    addNewGroup() {
      this.editableGroup = {
        name: 'My new group',
        description: '',
        icon: 'user',
        members: [],
        added_members: [],
      };
      this.isAddGroupModalOpened = true;
    },

    addGroupMembers() {
      const defaultGroup = this.listUserGroups.find((group) => group.name === 'All users') || {
        name: '',
        description: '',
        members: [],
      };
      defaultGroup.added_members = [];
      this.editableGroup = defaultGroup;
      this.isAddUsersModalOpened = true;
    },

    async loadAllUsersData() {
      const endpoints = [
        {
          endpoint: 'data?account-getusersbywhitelisteddomain',
          requesttype: 'account-getusersbywhitelisteddomain',
          key: 'userArray',
        },
        {
          endpoint: 'data?account-getrecentcontacts',
          requesttype: 'account-getrecentcontacts',
          key: 'contactsArray',
        },
      ];

      const responses = await Promise.all(
        endpoints.map(async (e) => {
          try {
            const resp = await fetch(e.endpoint, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ requesttype: e.requesttype }),
              withCredentials: true,
            });
            if (!resp.ok) {
              console.log('could not fetch', e.endpoint);
              return null;
            }
            return resp;
          } catch (err) {
            console.log('could not fetch', e.endpoint, err);
            return null;
          }
        }),
      );

      const jsonArray = await Promise.all(responses.map((r) => r?.json() || null));
      jsonArray.forEach((json, i) => (json ? json[endpoints[i].key] : null));
      const usersData = jsonArray.reduce(
        (acc, item, index) => [...acc, ...item[endpoints[index]?.key]],
        [],
      );
      const emails = usersData.map((user) => user.email);
      const allEmails = [...new Set([...emails, ...this.orgDefaultGroupUsers])];

      const userDictList = await this.loadProfilePictures(allEmails);
      for (let email of allEmails) {
        const name = usersData.find((user) => user.email === email)?.name;
        const profileImageUrl = userDictList.find((item) =>
          item.emails.includes(email),
        )?.profile_image_url;
        //TODO this data structure is a copy of harbourStore.profilePictures
        this.usersData = {
          [email]: {
            name,
            profileImageUrl,
            highlight: false,
          },
          ...this.usersData,
        };
      }
    },

    async loadProfilePictures(emails) {
      const emailsUnique = [...new Set(emails)];
      const handleCatch = () => ({ userdictlist: [] });
      const respData = await generalApiService.getProfilePicture(emailsUnique).catch(handleCatch);
      let userDictList = respData.userdictlist;
      userDictList = userDictList.filter((user) => user.profile_image_url);
      return userDictList;
    },

    processUserGroups(userGroups) {
      // Get organization user groups + generate super admin group
      const userEmail = this.harbourStore.contextDict.systememail;
      if (this.isOrgAdmin) {
        this.listUserGroups = userGroups.concat([
          this.generateDataOfAdminsGroup(this.organizationAdmins, this.orgUpdateDateTime),
        ]);
      } else {
        this.listUserGroups = userGroups;
      }

      // set admin property and id
      const setAdditionalProps = (group) => {
        const bgColors = ['#350894', '#078383', '#B015B3', '#BE381B', '#00853D', '#CB0E47'];
        const members = group.members || [];
        const currentUserAdminMember = members.find((member) => {
          return member.email === userEmail && member.role === 'admin';
        });
        const isGroupAdmin = Boolean(currentUserAdminMember);

        return {
          isGroupAdmin,
          id: group.group_id,
          bgColor: bgColors[Math.floor(Math.random() * bgColors.length)],
          ...group,
        };
      };

      this.listUserGroups = this.listUserGroups
        .sort((a, b) => b.created_at - a.created_at)
        .map(setAdditionalProps);

      this.userGroupsLoaded = true;
      this.isTableLoading = false;
    },

    assignOrganizationAdmins(organizationAdminsEmails) {
      this.organizationAdmins = Array.from(new Set(organizationAdminsEmails)).sort();
    },

    async getUserGroups() {
      this.userGroupsLoaded = false;
      this.isTableLoading = true;
      const userGroups = await userGroupsApiService.getUserGroups();
      this.processUserGroups(userGroups);
      this.listUserGroupsFiltered = JSON.parse(JSON.stringify(this.listUserGroups));
    },

    async getStoredValues({ disablePageLoader = false } = {}) {
      if (!disablePageLoader) this.isLoading = true;
      this.isTableLoading = true;
      try {
        const { data } = await Vue.prototype.$harbourData.post('data?settings-getstoredvalues', {
          requesttype: 'settings-getstoredvalues',
        });

        this.userFullName = data.userfullname;
        this.userNickName = data.userpreferredname;
        this.phoneNumber = data.userpreferredphonenumber;
        this.jobTitle = data.userpreferredjobtitle;
        this.initialPersonalData = {
          userFullName: data.userfullname,
          userNickName: data.userpreferredname,
          phoneNumber: data.userpreferredphonenumber,
          jobTitle: data.jobtitle,
        };

        //Stored signatures, initials json values
        this.storedSignatures = data.userstoredsignaturesjsonstrarray;
        if (this.storedSignatures !== null && this.storedSignatures.length > 0) {
          this.storedSignatures = this.storedSignatures.map(JSON.parse);
        }

        //Get stored initials
        this.storedInitials = data.userstoredinitialsjsonstrarray;
        if (this.storedInitials !== null && this.storedInitials.length > 0) {
          this.storedInitials = this.storedInitials.map(JSON.parse);
        }
        this.orgDefaultGroupUsers = data.organizationgroupmember;

        //If members found in users group, sort the names
        if (this.orgDefaultGroupUsers && this.orgDefaultGroupUsers.length > 0) {
          this.orgDefaultGroupUsers.sort();
        }

        this.orgCmsProperties = data.organizationcmsproperties;
        this.billingEmail = this.orgCmsProperties?.company_billing_email || '';

        if (this.orgCmsProperties?.stripe_customer_id) {
          this.isBillingDisabled = false;
        } else if (this.orgCmsProperties?.is_paid_webapp_account_level !== 'STARTER') {
          this.showBillingWarning = true;
        }
        this.dataLoaded = true;
      } catch (e) {
        this.isLoading = false;
        this.isTableLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Error while requesting initial data occurred. Please, try later.',
          position: 'is-top',
          type: 'is-danger',
        });
        Sentry.captureException(new Error(e));
      } finally {
        this.isLoading = false;
        this.isTableLoading = false;
      }
    },

    async createUserGroup(addAdmin) {
      this.isTableLoading = true;

      const members = addAdmin
        ? [
            {
              email: this.harbourStore.contextDict.systememail,
              role: 'admin',
            },
          ]
        : [];

      try {
        const newUserGroup = {
          description: this.editableGroup.description,
          icon: this.editableGroup.icon,
          name: this.editableGroup.name,
          created_by: this.getUserEmail,
          members,
        };
        const createResp = await userGroupsApiService.createUserGroup(newUserGroup);

        // set new group id
        newUserGroup.id = createResp.group_id;
        newUserGroup.group_id = createResp.group_id;

        // update harbour store to update list in dashboard group sharing
        this.listUserGroups.push(newUserGroup);

        await this.getUserGroups();
        this.harbourStore.updateContextUserGroupsData();
        this.templatesStore.getTemplateGroups();
        this.isTableLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Success - new user group created',
          position: 'is-top',
          type: 'is-success',
        });
        setTimeout(() => {
          this.agGridApi.forEachNode((node) => (node.rowIndex ? 0 : node.setSelected(true)));
        }, 1000);
        setTimeout(() => {
          this.agGridApi.forEachNode((node) => (node.rowIndex ? 0 : node.setSelected(false)));
        }, 2000);
      } catch (e) {
        this.isTableLoading = false;
        console.log('Failed to create user group. Exception:', e);
        Toast.open({
          duration: 2500,
          message:
            'Failed to create user group. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    async deleteUserGroup(groupId) {
      try {
        this.listUserGroups = this.listUserGroups.filter((group) => group.group_id !== groupId);
        await userGroupsApiService.deleteUserGroup(groupId);

        this.harbourStore.updateContextUserGroupsData();
        await this.templatesStore.getTemplateGroups();
        Toast.open({
          duration: 2500,
          message: 'Success - group deleted',
          position: 'is-top',
          type: 'is-success',
        });
      } catch (e) {
        Toast.open({
          duration: 2500,
          message:
            'Failed to delete user group. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    // type - addMembers, changeRole, deleteMember
    // data - group member info for changeRole, deleteUser
    async editUserGroup(type, data = {}) {
      this.isLoading = true;

      let groupMembers = [];
      const { role, memberEmail } = data;
      let memberIndex;
      const membersToChange = [];

      switch (type) {
        case 'addMembers':
          const addedMembers = this.editableGroup.added_members.map((email) => ({
            email: email?.toLowerCase().trim(),
            role: role,
          }));
          const filteredExistingMembers = this.editableGroup.members.filter(
            (member) => !addedMembers.find((added) => member.email === added.email),
          );

          groupMembers = [...filteredExistingMembers, ...addedMembers];

          // List users to be added
          membersToChange.push(...addedMembers.map((u) => ({ ...u, action: 'add' })));
          break;
        case 'changeRole':
          memberIndex = this.editableGroup.members.findIndex(
            (member) => member.email === memberEmail,
          );
          const member = { ...this.editableGroup.members[memberIndex], role };
          this.editableGroup.members.splice(memberIndex, 1, member);
          groupMembers = this.editableGroup.members;

          // List users to be updated
          membersToChange.push({ ...member, action: 'update' });

          break;
        case 'deleteMember':
          memberIndex = this.editableGroup.members.findIndex(
            (member) => member.email === memberEmail,
          );

          // List users to be deleted
          membersToChange.push({ ...this.editableGroup.members[memberIndex], action: 'delete' });

          this.editableGroup.members.splice(memberIndex, 1);
          groupMembers = this.editableGroup.members;
          break;
        default:
          groupMembers = this.editableGroup.members;
          break;
      }

      try {
        if (this.editableGroup.group_id === 'admins') {
          await Vue.prototype.$harbourData.post('data?settings-updateorganizationadmins', {
            requesttype: 'settings-updateorganizationadmins',
            adminEmails: JSON.stringify(membersToChange),
          });
        } else {
          await userGroupsApiService.updateUserGroup(this.editableGroup.group_id, {
            description: this.editableGroup.description || '',
            icon: this.editableGroup.icon,
            name: this.editableGroup.name,
            members: membersToChange.map((u) => ({
              email: u.email,
              role: u.role,
              action: u.action,
            })),
          });
        }

        const editGroupIndex = this.listUserGroups.findIndex(
          (group) => group.group_id === this.editableGroup.group_id,
        );
        this.editableGroup.members = groupMembers;

        this.listUserGroups.splice(editGroupIndex, 1, this.editableGroup);
        this.harbourStore.updateContextUserGroupsData();
        this.templatesStore.getTemplateGroups();
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Success - group information updated',
          position: 'is-top',
          type: 'is-success',
        });
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Failed to update user group. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      } finally {
        this.editableGroup = null;
      }
    },

    async deleteSignature(signatureId) {
      this.isLoading = true;

      try {
        await Vue.prototype.$harbourData.post('data?settings-deletestoredsignature', {
          requesttype: 'settings-deletestoredsignature',
          querytext: signatureId,
        });

        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Success - signature removed',
          position: 'is-top',
          type: 'is-success',
        });

        this.storedSignatures = this.storedSignatures.filter((s) => s.signatureid !== signatureId);

        if (this.storedSignatures.length > 0) {
          this.storedSignatures[0].issignaturedefault = true;
        }
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Failed to remove signature. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    confirmDeleteSignature(signatureId, signatureTextLabel) {
      Dialog.confirm({
        title: 'Delete stored signature',
        message: `Are you sure you want to fully delete the signature (${signatureTextLabel})?`,
        type: 'is-info',
        onConfirm: async () => {
          await this.deleteSignature(signatureId);
        },
      });
    },

    async deleteInitials(initialsId) {
      this.isLoading = true;

      try {
        await Vue.prototype.$harbourData.post('data?settings-deletestoredinitials', {
          requesttype: 'settings-deletestoredinitials',
          querytext: initialsId,
        });

        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Success - initials removed',
          position: 'is-top',
          type: 'is-success',
        });

        this.storedInitials = this.storedInitials.filter((s) => s.initialsid !== initialsId);
        if (this.storedInitials.length > 0) {
          this.storedInitials[0].isinitialsdefault = true;
        }
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Failed to remove initials. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    confirmDeleteInitials(initialsId, initialsTextLabel) {
      Dialog.confirm({
        title: 'Delete stored signature',
        message: `Are you sure you want to fully delete the initials (${initialsTextLabel})?`,
        type: 'is-info',
        onConfirm: async () => {
          await this.deleteInitials(initialsId);
        },
      });
    },

    async saveSignature(props) {
      let signatureId =
        'storedsignature-' + Date.now() + '_' + Math.floor(Math.random() * 1000000000000);
      this.isLoading = true;

      try {
        const { data } = await Vue.prototype.$harbourData.post(
          'data?settings-updatestoredsignature',
          {
            requesttype: 'settings-updatestoredsignature',
            signatureId: signatureId,
            signatureTextLabel: props.textLabel,
            signatureImageSrc: props.imageSrc,
            signatureThumbnailSrc: props.thumbnailSrc,
            signatureSourceType: props.sourceType,
            isSignatureDefault: props.isDefault,
          },
        );
        this.storedSignatures = data.storedSignatures.map(JSON.parse);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Update: unfortunately, while processing your update, ' +
            'an error occurred and we are unable to save this signature stored at this time.' +
            ' Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    async saveInitials(props) {
      let initialsId =
        'storedinitials-' + Date.now() + '_' + Math.floor(Math.random() * 1000000000000);
      this.isLoading = true;

      try {
        const { data } = await Vue.prototype.$harbourData.post(
          'data?settings-updatestoredinitials',
          {
            requesttype: 'settings-updatestoredinitials',
            initialsId: initialsId,
            initialsTextLabel: props.textLabel,
            initialsImageSrc: props.imageSrc,
            initialsThumbnailSrc: props.thumbnailSrc,
            initialsSourceType: props.sourceType,
            isInitialsDefault: props.isDefault,
          },
        );
        this.storedInitials = data.storedInitials.map(JSON.parse);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Update: unfortunately, while processing your update, ' +
            'an error occurred and we are unable to save this signature stored at this time.' +
            ' Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    processOrgData(data) {
      this.orgData = data;
      this.initialOrgData = Object.assign({}, data);
      const organizationAdminsEmails = data.admins.map((admin) => admin.user_email);
      this.assignOrganizationAdmins(organizationAdminsEmails);

      this.harbourStore.setPersonalization({
        logo: data.logo_image,
        banner: data.banner_image,
        theme: data.color_theme,
      });

      this.isLoading = false;
      this.orgDataLoaded = true;
    },

    async getCurrentOrganization() {
      try {
        this.isLoading = true;
        const data = await organizationApiService.getOrganizationById(this.currentOrgId);
        this.processOrgData(data);
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Unfortunately, an error occurred while requesting organization details. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
        Sentry.captureException(new Error(e));
      }
    },

    async updateCurrentOrgData() {
      try {
        this.isLoading = true;
        await organizationApiService.updateOrganization(this.currentOrgId, {
          ...(this.orgData.description &&
            this.orgData.description !== this.initialOrgData.description && {
              description: this.orgData.description,
            }),
          ...(this.orgData.name &&
            this.orgData.name !== this.initialOrgData.name && { name: this.orgData.name }),
          ...(this.orgData.website &&
            this.orgData.website !== this.initialOrgData.website && {
              website: this.orgData.website,
            }),
        });

        await this.getCurrentOrganization();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message:
            'Unfortunately, an error occurred while requesting organization details. Try again later or contact support@harbourshare.com',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },

    async updateOrgLogo(file) {
      if (!isUploadedFileSizeValid(file)) return;

      try {
        this.isLoading = true;

        const [maxWidth, maxHeight] = [800, 800];
        const imageThumbnail = await createThumbnailFromFile(file, maxWidth, maxHeight);

        await organizationApiService.updateOrganization(this.currentOrgId, {
          logo_image: imageThumbnail,
        });

        this.orgData.logo_image = imageThumbnail;

        this.harbourStore.setPersonalization({
          logo: this.orgData.logo_image,
          banner: this.orgData.banner_image,
          theme: this.orgData.color_theme,
        });

        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Organization logo was updated successfully',
          position: 'is-top',
          type: 'is-success',
        });
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Unable to update logo image',
          position: 'is-top',
          type: 'is-warning',
        });
      }
    },

    async updateOrgBanner(file) {
      if (!isUploadedFileSizeValid(file)) return;

      try {
        this.isLoading = true;

        const [maxWidth, maxHeight] = [1600, 1600];
        const imageThumbnail = await createThumbnailFromFile(file, maxWidth, maxHeight);

        await organizationApiService.updateOrganization(this.currentOrgId, {
          banner_image: imageThumbnail,
        });

        this.orgData.banner_image = imageThumbnail;

        this.harbourStore.setPersonalization({
          logo: this.orgData.logo_image,
          banner: this.orgData.banner_image,
          theme: this.orgData.color_theme,
        });

        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Organization banner was updated successfully',
          position: 'is-top',
          type: 'is-success',
        });
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Unable to update banner image',
          position: 'is-top',
          type: 'is-warning',
        });
      }
    },

    async updateColorTheme(themeName) {
      try {
        this.isLoading = true;

        await organizationApiService.updateOrganization(this.currentOrgId, {
          color_theme: themeName,
          banner_image: '',
        });

        await this.getCurrentOrganization();
        this.harbourStore.setPersonalization({
          logo: this.orgData.logo_image,
          banner: this.orgData.banner_image,
          theme: this.orgData.color_theme,
        });

        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Organization theme was updated successfully',
          position: 'is-top',
          type: 'is-success',
        });
      } catch (e) {
        this.isLoading = false;
        Toast.open({
          duration: 2500,
          message: 'Unable to update color theme',
          position: 'is-top',
          type: 'is-warning',
        });
      }
    },

    generateDataOfAdminsGroup(orgAdminEmails, orgUpdateDateTime) {
      return {
        group_id: 'admins',
        name: 'Super admins',
        icon: 'star',
        description: 'Super admins of your organization',
        created_at: this.harbourStore.contextDict.org_createdateepochms,
        updated_at: orgUpdateDateTime,
        created_by: 'default',
        organization_id: this.harbourStore.contextDict.organizationid,
        // todo: requires better solution
        members: orgAdminEmails.map((email) => ({
          group_id: 'admins',
          organization_id: this.harbourStore.contextDict.organizationid,
          email: email,
          role: 'admin',
        })),
        is_default: true,
      };
    },

    dateFormatter(date) {
      return new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
});