import axios from 'axios';

/**
 * @typedef {Object} RequestCreateDocuments Request for creating a document in a superdoc
 * @property {Integer} version_number The version number of the superdoc
 * @property {String} title The title of the document
 * @property {String} file_extension The file extension of the document
 * @property {Integer} position_number The position for this doc inside the superdoc
 */

/**
 * @typedef {Object} HarbourSuperdoc The superdoc data for creating a superdoc
 * @property {String} title The title of the superdoc
 * @property {File} file The file object for the superdoc
 */

/**
 * @typedef {Object} CreateSuperdocResponse The response after creating the superdoc
 * @property {String} superdoc_id The ID of the superdoc
 * @property {Integer} number The version number of the superdoc
 * @property {String} title The title of the superdoc
 * @property {String} name The name of the version ie: "Version 1"
 */

/**
 * @typedef {Object} CreateDocumentResponse The response after creating a document
 * @property {String} upload_file_path The path to upload the file to
 * @property {String} upload_collaboration_path The path to upload the collaboration file to
 */

/**
 * @typedef {Object} GetSuperdocResponse The response after getting a superdoc
 * @property {String} id The ID of the superdoc
 * @property {String} title The title of the superdoc
 * @property {String} state The state of the superdoc ie: Draft
 * @property {String} created_at The date the superdoc was created
 * @property {String} created_by The creator of the superdoc
 * @property {String} updated_at The date the superdoc was last updated
 * @property {String} updated_by The user who last updated the superdoc
 */

/**
 * @typedef {Object} GetSuperdocDocumentResponse A single document in a superdoc response
 * @property {String} document_id The ID of the document
 * @property {String} superdoc_id The ID of the superdoc
 * @property {String} title The title of the document
 * @property {String} file_extension The file extension of the document
 * @property {String} version_number The version number of the document
 * @property {String} position_number The position of the document in the superdoc
 * @property {String} created_at The date the document was created
 * @property {String} created_by The user who created the document
 * @property {String} updated_at The date the document was last updated
 * @property {String} updated_by The user who last updated the document
 * @property {String} signed_url The file data for the document
 * @property {String} collaboration_path The path to the collaboration file
 */

/**
 * @typedef {Object} SuperdocServiceType The Superdoc service
 * @property {Function} getSuperdocs Get all superdocs
 * @property {Function} createSuperdoc Create a superdoc
 * @property {Function} getSuperdoc Get a superdoc
 * @property {Function} getSuperdocDocuments Get all documents for a superdoc
 * @property {Function} getSuperdocDocument Get a superdoc document
 * @property {Function} getDocumentFilesForSuperdoc Get all file data for a superdoc
 * @property {Function} deleteSuperdoc Delete a superdoc
 * @property {Function} updateSuperdoc Update a superdoc
 * @property {Function} getCollaborationServiceUrl Get the collaboration service URL
 * @property {Function} getLivetestToken Get the livetest token
 */

const SUPERDOC_EXTENSIONS = Object.freeze({
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/pdf': 'pdf',
});

const log = (...args) => console.debug('🦋🦋\n\t[SuperDoc]', ...args, '\n🦋🦋');

/**
 * Get all superdocs
 * @returns {Object[]} The list of superdocs
 */
export const getSuperdocs = async (filter = {}) => {
  const filterString = new URLSearchParams(filter).toString();
  const url = `/api/superdocs${filterString ? `?${filterString}` : ''}`;

  try {
    const { data, status } = await axios.get(url);
    return status === 200 ? data.items : [];
  } catch (error) {
    return [];
  }
};


/**
 * Main handler for creating a new superdoc. We create superdocs in three steps:
 * 1. Create the superdoc record
 * 2. Create the document record
 * 3. Upload the file to the document
 * 4. Copy the version so we have a current version
 * @async
 * @param {HarbourSuperdoc} superdoc The superdoc data for initializing on the server
 * @returns {Object} The created superdoc info
 */
export const createSuperdoc = async (superdoc) => {
  // 1. Create the superdoc record
  const newSuperdoc = await createSuperdocRecord(superdoc);
  const { superdoc_id, number: versionNumber } = newSuperdoc;

  // 2. Create the document record
  const documentPayload = {
    version_number: versionNumber,
    title: superdoc.file.name,
    file_extension: SUPERDOC_EXTENSIONS[superdoc.file.type],
    position_number: 0,
  };

  const documentResult = await createSuperdocDocument(superdoc_id, documentPayload);
  const { upload_file_path: signedUrl } = documentResult;

  // 3. Upload the file to the document
  await uploadDocument(superdoc.file, superdoc.file.name, superdoc.file.type, signedUrl);

  log('Superdoc created:', superdoc.file.name, newSuperdoc, documentResult);
  const documents = [
    {
      id: documentResult.document_id,
      type: documentResult.file_extension,
      data: superdoc.file,
    }
  ];

  return {
    id: superdoc_id,
    created_at: newSuperdoc.created_at,
    created_by: newSuperdoc.created_by,
    updated_at: newSuperdoc.updated_at ? newSuperdoc.updated_at : newSuperdoc.created_at,
    updated_by: newSuperdoc.updated_by,
    documents,
    type: 'superdoc',
    role: 'agreement',
    title: superdoc.file.name,
    superdocDataReference: {
      superdocId: superdoc_id,
      superdoc: newSuperdoc,
      title: superdoc.file.name,
      documents,
      collaborators: [],
    }
  };
};

const copySuperdocVersion = async (superdocId, versionNumber) => {
  const url = `/api/superdocs/${superdocId}/versions/${versionNumber}/copy`;
  const result = await axios.post(url);
  return result.data;
};

/**
 * Create a new superdoc on the server. Requires an initial file for the title.
 *
 * @async
 * @param {HarbourSuperdoc} superdoc The superdoc data for initializing on the server
 * @returns {CreateSuperdocResponse} The response after creating the superdoc
 */
const createSuperdocRecord = async (superdoc) => {
  const { name: title } = superdoc.file;
  const payload = {
    title,
  };
  const result = await axios.post('/api/superdocs', payload);
  return result.data;
};

/**
 * Add a document to a superdoc
 * @async
 * @param {String} superdoc_id The ID of the superdoc for this document
 * @param {RequestCreateDocuments} documentPayload
 * @returns {CreateDocumentResponse} The response after creating a document
 */
const createSuperdocDocument = async (superdoc_id, documentPayload) =>  {
  const url = `/api/superdocs/${superdoc_id}/documents`;
  const createDocumentResult = await axios.post(url, documentPayload);
  return createDocumentResult.data;
};

/**
 * Upload the file to a document
 *
 * @async
 * @param {File} file The file object for upload
 * @param {String} url The GCS signed URL to upload the file to
 * @returns {void}
 */
const uploadDocument = async (data, name, mimeType, url) => {
  log('Uploading document:', name, 'to', url);
  const formData = new FormData();
  formData.append('file', data);
  const response = await axios.put(url, data, {
    headers: { 'Content-Type': mimeType },
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      log(`[${name}] Upload progress: ${percentCompleted}%`);
    }
  });
  log('Document uploaded:', name, response.data);
};

/**
 * Process the documents for a superdoc
 * @param {Object[]} documents
 * @param {Object[]} documentFiles
 * @returns
 */
const processDocs = (documents, documentFiles = []) => {
  return documents.map((doc, index) => {
    if (index >= documentFiles.length) return;
    const superdocData = {
      id: doc.document_id,
      type: doc.file_extension,
    };

    if (index < documentFiles.length) superdocData.data = documentFiles[index].mainFile;
    return superdocData;
  });
};

/**
 * Get a single superdoc
 * @async
 * @param {String} id
 * @returns {GetSuperdocResponse} The response after getting a superdoc
 */
export const getSuperdoc = async (id) => {
  if (!id) return;
  const superdoc = await getSuperdocData(id);
  const documents = await getSuperdocDocuments(id);
  const documentFiles = await getDocumentFilesForSuperdoc(documents);

  const processedDocs = processDocs(documents, documentFiles);
  let jsonState;
  if (documentFiles?.length > 0) {
    jsonState = documentFiles[0].parsedJson;
  };

  return {
    superdocId: id,
    superdoc,
    title: superdoc.title,
    documents: processedDocs,
    jsonState,
    collaborators: superdoc.collaborators,
  }

};

/**
 *
 * @param {String} superdocId Get a superdoc version
 * @param {Integer | null} versionNumber The version number. If null, get the latest version
 * @returns {Object} The superdoc version
 */
const getSuperdocVersion = async (superdocId, versionNumber = null) => {
  let url = `/api/superdocs/${superdocId}/versions`;
  if (versionNumber !== null) url += `/${versionNumber}`;

  url += "?size=1";
  const result = await axios.get(url);
  console.debug('Superdoc version:', result.data);
  return result.data;
};

/**
 * Get a superdoc
 * @async
 * @param {String} id
 * @returns {Object} The superdoc data
 */
const getSuperdocData = async (id) => {
  const superdocResult = await axios.get(`/api/superdocs/${id}`);
  return superdocResult.data;
}

/**
 * Get all documents for a superdoc
 * @async
 * @param {String} id The ID of the superdoc
 * @returns {GetSuperdocDocumentResponse[]} The response after getting all documents for a superdoc
 */
export const getSuperdocDocuments = async (id) => {
  const documentsResult = await axios.get(`/api/superdocs/${id}/documents`);
  const currentOnly = documentsResult.data.items.filter(doc => doc.version_number === 0);
  return currentOnly
};

/**
 * Get a superdoc document
 * @async
 * @param {String} documentId The ID of the document
 * @returns {GetSuperdocDocumentResponse} The response after getting a superdoc document
 */
export const getSuperdocDocument = async (documentId) => {
  const documentResult = await axios.get(`/api/documents/${documentId}`);
  return documentResult.data;
};

/**
 * Get all file data for a superdoc
 * @async
 * @param {GetSuperdocDocumentResponse[]} documents
 * @returns {File[]} The files for the superdoc
 */
export const getDocumentFilesForSuperdoc = async (documents) => {
  console.debug('Getting files for superdoc:', documents);
  const documentFiles = await Promise.all(documents.map(async (doc) => {
    console.debug('Getting file for document:', doc);
    const document = await getSuperdocDocument(doc.document_id);
    const { download_link } = document;
    console.debug('\n\n DOWNLOAD LINK', download_link, '\n\n');
    const trace = `SuperdocId ${doc.superdoc_id}, DocumentId ${doc.document_id}`;
    if (!download_link) throw new Error(`No download link found for document. ${trace}`);

    const mainFileResponse = await axios.get(download_link, { responseType: 'blob' });
    const blob = mainFileResponse.data;
    const filename = doc.title;
    const mainFile = new File([blob], filename, { type: blob.type });

    return {
      mainFile,
      jsonData: null,
    };
  }));

  return documentFiles
};

/**
 * Delete a superdoc
 * @async
 * @param {String} id The ID of the superdoc
 * @returns {void}
 */
export const deleteSuperdoc = async (id) => {
  log('Deleting superdoc:', id, '......');
  const result = await axios.delete(`/api/superdocs/${id}`);
  log('Superdoc deleted:', id, result.data);
  return result.data;
};

/**
 * Update a superdoc
 * @param {String} id
 * @param {Object} payload See the API for the possible payload
 * @returns
 */
export const updateSuperdoc = async (id, payload) => {
  const result = await axios.put(`/api/superdocs/${id}`, payload);
  return result.data;
};

/**
 * Get the current collaboration URL
 * @returns {String} The collaboration URL
 */
export const getCollaborationServiceUrl = () => {
  const WEB_SERVICE_URL = 'wss://superdoc-collaboration.myharbourshare.com';
  const LOCAL_URL = 'ws://localhost:3050';

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  let serviceUrl = WEB_SERVICE_URL;

  // Check if the host is localhost
  if (url.host.includes('localhost')) serviceUrl = LOCAL_URL;
  serviceUrl = `${serviceUrl}/docs`;
  return serviceUrl;
};

/**
 * In livetest and localdev, we need to get a collaboration token
 * @returns ]{String} The collaboration token
 */
export const getLivetestToken = async () => {
  const currentHost = window.location.host;
  if (currentHost.endsWith('uc.r.appspot.com')) {
      const response = await axios.get('/collaboration-token');
      return response.data;
  };
  return 'token';
};

export const mimeTypeToExtension = (mimeType) => {
  return SUPERDOC_EXTENSIONS[mimeType];
};

/**
 * All exposed services
 * @returns {SuperdocServiceType} The Superdoc service
 */
export const SuperdocService = {
  getSuperdocs,
  createSuperdoc,
  getSuperdoc,
  getSuperdocDocuments,
  getSuperdocDocument,
  getDocumentFilesForSuperdoc,
  deleteSuperdoc,
  updateSuperdoc,
  getCollaborationServiceUrl,
  getLivetestToken,
  mimeTypeToExtension,
};
